.achievements-list {
  grid-area: 2 / 1 / 3 / 2;

  .achievement-items {
    column-gap: 16px;
    row-gap: 16px;

    .achievement-item {
      flex: 0;
      max-width: 102px;
      max-height: 102px;

      .image-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 102px;
        height: 102px;
        padding-right: 2px;
        background-color: #fff1d9;
        border-radius: 50%;

        img {
          width: 56px;
          height: 56px;
        }
      }
    }
  }
}