.image-uploader {
  cursor: pointer;
  user-select: none;

  text-align: center;
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid $warm-gray-300;

  transition: background-color 0.1s ease-in-out, border 0.1s ease-in-out;

  &:hover,
  &.active {
    border: 1px solid $primary-600;
    background: $primary-100;
  }

  &.uploaded {
    text-align: left;

    .image-uploader {
      &__content {
        padding: 16px;

        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        gap: 16px;
      }
    }
  }

  &.disabled {
    cursor: not-allowed;
    background: $warm-gray-100;
    border: 1px solid $warm-gray-200;

    .image-uploader {
      &__img {
        opacity: 0.4;
      }

      &__title {
        color: $warm-gray-500;
      }

      &__subtitle {
        color: $warm-gray-300;
      }

      &__delete {
        cursor: not-allowed;
        color: $warm-gray-400;
      }
    }
  }

  &__content {
    padding: 20px 4px 24px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__img {
    display: inline-block;
    width: 80px;
    height: 80px;
    border-radius: 6px;
    object-fit: cover;
  }

  &__title {
    margin: 0;
    color: $warm-gray-900;

    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;

    span {
      color: $blue-500;
      text-decoration: underline;
      transition: color 0.1s ease-in-out;

      &:hover {
        color: $blue-800;
      }
    }
  }

  &__subtitle {
    margin: 0;
    color: $warm-gray-600;

    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  &__delete {
    display: flex;
    align-items: center;
    gap: 4px;

    background: none;
    border: none;
    margin: 0;
    padding: 0;

    margin-top: 6px;

    color: $warm-gray-600;

    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;

    &:hover {
      color: $red;
    }
  }

  &__icon {
    margin-bottom: 10px;
    color: $primary-700;
  }

  @include media-breakpoint-down(xs) {
    &.uploaded {
      text-align: center;

      .image-uploader {
        &__content {
          padding: 16px;

          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: 16px;
        }

        &__delete {
          margin: 6px auto 0;
        }
      }
    }
  }
}

.image-uploader-modal {
  &__btn {
    margin-top: 20px;
  }

  &__error {
    margin: 12px 0 0;
    color: $red;

    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;

    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
  }
}

.image-uploader-zoom {
  margin-top: 20px;

  &__input {
    width: 100%;
    height: 20px;
    appearance: none;
    -webkit-appearance: none;

    &::-webkit-slider-thumb {
      width: 28px;
      height: 20px;
      margin-top: -6px;
      box-shadow: 1px 1px 1px #aaaa;
      background: $primary-600;
      border: 1px solid $primary-700;
      border-radius: $round-lg;
      cursor: pointer;
      -webkit-appearance: none;
    }

    &::-webkit-slider-runnable-track {
      width: 100%;
      height: 10px;
      background: $primary-400;
      border: 1px solid $primary;
      border-radius: $round-sm;
      cursor: pointer;
    }
  }
}

.image-uploader-cropper {
  position: relative;
  width: 100%;
  padding-top: 56.25%;
  height: 0;

  &__container {
    width: 100%;
    height: 100%;
    border-radius: 8px;
  }

  &__loading {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;
    width: 100%;
    height: 100%;
    background-color: #fff;
  }

  &__area {
    border-color: #eeeeee;

    &::before,
    &::after {
      border-color: #eee;
    }
  }
}
