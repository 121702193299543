.pedigree-modal {
  .modal-body {
    .body-message {
    }

    .form-group-radio {
      display: flex;
      flex-direction: row;
      height: 50px;
      padding: 0;
      margin: 25px 0 0;

      .form-group-item {
        width: 50px;
        height: 50px;
        padding: 0;
        margin: 0 50px 0 0;

        .form-label {
          position: static;
          font-size: unset;
          display: flex;
          align-items: center;

          .form-check-input {
            width: 20px;
            height: 20px;
            position: unset;
            margin-right: 10px;
            flex-shrink: 0;


            input {
              appearance: none;
              width: 20px;
              height: 20px;
              border: 1px solid $neutral-300;
              border-radius: 50%;
              background-clip: content-box;
              padding: 4px;
              accent-color: $primary;

              &:checked {
                background-color: $primary;
              }

            }
          }
        }
      }
    }

    .button-container {
      width: 136px !important;
    }
  }
}
