.control-live {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 24px;

  border-radius: 8px;
  border: 1px solid $warm-gray-200;
  background: $warm-gray-50;

  &.--is-completed {
    background: $white;
  }

  &__content {
    margin-right: 6px;
  }

  &__title {
    display: flex;
    align-items: center;

    h4 {
      margin: 0;
      color: $warm-gray-900;

      font-family: Inter;
      font-size: 20px;
      font-weight: 600;
      line-height: 30px;
    }
  }

  &__pulse {
    width: 12px;
    height: 12px;
    background-color: $red;
    border-radius: 50%;

    margin-left: 8px;
  }

  &__text {
    color: $warm-gray-900;

    font-size: 16px;
    font-family: $font-family-base;
    font-weight: 400;
    line-height: 24px;

    &-link {
      color: $blue-600;

      font-size: 16px;
      font-family: $font-family-base;
      font-weight: 400;
      line-height: 24px;
      text-decoration: underline;

      transition: color 0.1s ease-in-out;

      &:hover,
      &:focus {
        color: $blue-800;
        text-decoration: underline;

        svg {
          color: $blue-800;
        }
      }
    }

    &-icon {
      vertical-align: top;

      width: 24px;
      height: 24px;
      color: $blue-500;

      transition: color 0.1s ease-in-out;

      &.--red {
        color: $red-600;
      }
    }
  }

  &__toggle {
    &.--is-disabled {
      .react-switch-bg,
      .react-switch-handle {
        cursor: not-allowed !important;
      }
    }
  }

  @include media-breakpoint-down(sm) {
    padding: 10px 16px;

    &__link {
      font-size: 14px;
      line-height: 20px;
    }
  }
}
