.upgrade-message {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;

  .image-container {
    max-width: 256px;
    max-height: 220px;
    margin-top: 24px;
  }

  .upgrade-message-text {
    max-width: 528px;
    margin-top: 40px;
    margin-bottom: 16px;
    color: $neutral-900;
    text-align: center;

    @include upgrade-message-text;
  }

  .upgrade-message-hint {
    max-width: 424px;
    margin-bottom: 40px;
    color: $neutral-900;
    text-align: center;

    @include upgrade-message-hint;
  }
}
