.page.library {
  .header {
    padding-bottom: 0 !important;
  }

  .page-body {
    flex: 0 0 !important;
    padding-right: 0 !important;
    .content {

      .blog-cards-skeleton {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        overflow-x: hidden;

        .card-skeleton {
          margin-right: 16px;
          min-width: 271px;
        }
      }

      .resource-skeleton {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        overflow: hidden;

        .resource-item {
          margin-bottom: 12px;
          min-width: 558px;
        }
      }

      .blog-menu {
        width: 100%;

        .card {
          margin-right: 16px;
          box-shadow: 0 0 14px rgba(24, 39, 75, 0.08), 0 0 8px rgba(24, 39, 75, 0.04);
          border-radius: 8px;
          user-select: none;
          overflow: hidden;

          &:hover {
            cursor: pointer;
          }

          .card-img-overlay {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            padding: 8px !important;
            background: linear-gradient(180deg, rgba(27, 27, 27, 0) 49.74%, rgba(27, 27, 27, 0.5) 81.37%);

            .card-title {
              color: $neutral-100;
              font-size: 16px !important;
              line-height: 20px !important;

              @include poppins-xl-font;
            }

            .card-text {
              color: $neutral-300;
              font-size: 12px;
              font-weight: 400;
              line-height: 20px;
            }

            @import '../../badge';

            .custom-badge {
              margin-bottom: auto;
            }
          }
        }
      }

      .blog-resources {
        display: flex;
        flex-direction: column;

        .resource-item {
          display: flex;
          align-items: center;
          background-color: $neutral-100;
          border-radius: 8px;
          max-width: 558px;
          margin-bottom: 12px;
          padding: 12px 12px 12px 17px;
          font-size: 16px !important;
          line-height: 20px !important;

          @include poppins-l-font;

          .doc-icon {
            margin-right: 13px;

          }
        }
      }
    }
  }
}