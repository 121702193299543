.coming-soon-modal {
  .modal-content {
    box-shadow: $box-shadow;
    border: none;
    border-radius: 8px;

    .coming-soon-body {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0 40px 48px;

      .modal-title {
        margin-top: 48px;
        text-align: center;

        @include poppins-xl-font;
      }

      .modal-description {
        margin: 16px 0 0;
        color: $neutral-800;
        text-align: center;
        max-width: 343px;

        @include inter-m-font;
      }
    }
  }
}
