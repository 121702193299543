.trial-page {
  height: calc(100vh - 72px);

  @include flexible-values((
    prop: padding,
    directions: (left right top bottom),
    values: (10px, 10px, 32px, 63px),
    values-sm: (8px, 8px, 24px, 27px),
  ));


  .trial-heading {
    color: $gray-900;
    text-align: center;
    font-family: $secondary-font, $font-family-base;
    font-size: 24px;
    font-weight: 600;
    line-height: 27px;

    @include flexible-value((
      prop: line-height,
      value: 27px,
      value-sm: 34px,
    ));

    @include flexible-value((
      prop: margin-bottom,
      value: 40px,
      value-sm: 24px,
    ));
  }

  .tariff-cards-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    column-gap: 32px;
    row-gap: 16px;

    @include flexible-value((
      prop: flex-direction,
      value: row,
      value-sm: column,
    ));

    .tariff-card {
      box-shadow: 0 4px 22px -6px rgba(24, 39, 75, 0.08), 0px 8px 40px -4px rgba(24, 39, 75, 0.04);
      background: $white;
      border-radius: 16px;

      @include flexible-value((
        prop: max-width,
        value: 398px,
        value-sm: 425px,
      ));

      .section {
        display: flex;
        flex-direction: column;
        padding: 24px 32px 16px 32px;

        &:first-child {
          border-bottom: 1px solid $gray-200;
        }


        .title {
          margin-bottom: 12px;
          color: $gray-900;
          text-align: center;
          text-transform: uppercase;
          font-family: $secondary-font, $font-family-base;
          font-size: 20px;
          font-weight: 600;
          line-height: 23px;
          letter-spacing: 0.02em;
        }

        .description {
          margin-bottom: 0;
          color: $gray-700;
          text-align: center;
          font-family: $secondary-font, $font-family-base;
          font-size: 14px;
          font-weight: 400;
          line-height: 22px;
          max-width: 293px;
        }

        .price {
          margin-top: 12px;
          color: $gray-400;
          text-align: center;
          font-family: $secondary-font, $font-family-base;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
        }

        .custom-link {
          text-decoration: none;
          @include link-button();

          margin-top: 24px !important;
        }

        .features-list {
          padding-left: 0;
          margin-bottom: 0;
          list-style: none;

          .features {
            display: flex;

            svg {
              height: 20px;
              width: 20px;
              margin-right: 10px;
              flex-shrink: 0;
            }

            p {
              font-family: $secondary-font, $font-family-base;
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 20px;
              color: $gray-700;
              margin-bottom: 14px;
            }
          }
        }

      }

    }

  }
}
