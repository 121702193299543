.custom-select-label {
  padding: 8px 12px;
  margin: 16px;
  background-color: $neutral-150;
  border-radius: $round-md;

  @include default-font-size;
}

.group-label {
  color: $neutral-800;
  font-family: $font-family-base;
  font-weight: 400;


  @include label-font-size;
}

.custom-select-options {
  .react-custom-select__option {
    color: $neutral-900;

    @include inter-m-font;
  }
}

.react-custom-select__group-heading, .react-custom-select__option {
  padding-left: 16px;
  padding-right: 16px;
}
