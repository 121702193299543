@import 'auth-header';

.auth-page {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: calc(100vh - 72px);
  padding: 0 40px;
  box-sizing: border-box;
  background-color: $gray-50;
  overflow: auto;

  @include flexible-values((
    prop: padding,
    directions: (top left bottom right),
    values: (56px, 40px, 100px, 40px),
    values-xs: (23px, 8px, 47px, 8px),
  ));

  @include media-breakpoint-down(sm) {
    margin: 0 env(safe-area-inset-right, 20px)
      env(safe-area-inset-bottom, 20px) env(safe-area-inset-left, 20px) !important;
  }

  .actions {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include flexible-value((
      prop: row-gap,
      value: 8px,
      value-xs: 12px,
    ));

    @include flexible-value((
      margin-top: 40px,
      value: 40px,
      value-xs: 32px,
    ));


    @include flexible-value((
      prop: margin-top,
      value: 40px,
      value-sm: 32px,
    ));

    .action {
      font-family: $secondary-font, $font-family-base;
      font-size: 16px;
      line-height: 22px;
      font-weight: 400;
      color: $gray-900;

      .link {
        color: #3B82F6;
        text-decoration: underline;

        @include default-font-size;
      }
    }
  }

  .react-custom-select__control {
    background-color: transparent;

    @include default-font-size;
  }

  @import 'auth-form';
}

@import 'recovery';
@import 'verify-email';


