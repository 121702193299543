.custom-domain-modal-tip {
  .cdm-body {
    padding: 0 48px 40px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .description {
      margin-top: 0;
      margin-bottom: 40px;
      font-family: $font-family-base;
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;

      ol {
        margin-bottom: 0;
        li {
          &:not(:last-child) {
            margin-bottom: 10px;
          }

          .link {
            color: $link-font-color;
          }
        }
      }
    }

    .confirm-btn {
      margin-top: auto;
    }
  }
}
