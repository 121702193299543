.burger-container {
  z-index: 100;
  display: none;
  min-height: 54px;

  @include flexible-values((
    prop: padding,
    directions: (top bottom right left),
    values: (13px, 28px, 40px, 28px),
    values-lg: (13px, 15px, 20px, 20px),
    values-sm: (13px, 10px, 12px, 12px),
  ));

  @include media-breakpoint-down(sm) {
    display: block;
  }

  .burger-btn {
    display: flex;
    position: relative;
    align-items: center;
    background-color: transparent;
    cursor: pointer;
    border: 0;
    padding: 0;

    &::after {
      content: '';
      position: absolute;
      top: -13px;
      left: -6px;
      bottom: -13px;
      right: -9px;
    }

    @include flexible-value((
      prop: display,
      value: none,
      value-sm: flex,
    ));

  
    .burger-text {
      margin: 0 0 0 10px;
      color: $neutral-900;

      @include poppins-m-font;
    }
            
    .burger {
      position: relative;
      display: flex;
      align-items: center;
      width: 24px;
      height: 20px;
    
      .burger-block {
        width: 30px;
      }
      
      .burger-span {
        display: block;
        width: 19px;
        border-radius: 3px;
        height: 2px;
        background: $black;
        transition: all .3.5s ease-in;
        position: relative;
      }
      
      .burger-span + .burger-span {
        margin-top: 4px;
      }
      
      .active .burger-span {
        &:nth-child(1) {
          animation: ease .3s top forwards;
        }
      }
      
      .not-active .burger-span {
        &:nth-child(1) {
          animation: ease .3s top-reverse forwards;
        }
      }
      
      .active .burger-span {
        &:nth-child(2) {
          animation: ease .3s scaled forwards;
        }
      }
      
      .not-active .burger-span {
        &:nth-child(2) {
          animation: ease .3s scaled-reverse forwards;
        }
      }
      
      .active .burger-span {
        &:nth-child(3) {
          animation: ease .3s bottom forwards;
        }
      }
      
      .not-active .burger-span {
        &:nth-child(3) {
          animation: ease .3s bottom-reverse forwards;
        }
      }
    }
  }
}

@keyframes top {
  0% {
    top: 0;
    transform: rotate(0);
  }
  50% {
    top: 6px;
    transform: rotate(0);
  }
  100% {
    top: 6px;
    transform: rotate(45deg);
  }
}

@keyframes top-reverse {
  0% {
    top: 6px;
    transform: rotate(45deg);
  }
  50% {
    top: 6px;
    transform: rotate(0deg);
  }
  100% {
    top: 0;
    transform: rotate(0deg);
  }
}

@keyframes bottom {
  0% {
    bottom: 0;
    transform: rotate(0);
  }
  50% {
    bottom: 6px;
    transform: rotate(0);
  }
  100% {
    bottom: 6px;
    transform: rotate(135deg);
  }
}

@keyframes bottom-reverse {
  0% {
    bottom: 6px;
    transform: rotate(135deg);
  }
  50% {
    bottom: 6px;
    transform: rotate(0);
  }
  100% {
    bottom: 0;
    transform: rotate(0);
  }
}

@keyframes scaled {
  50% {
    transform: scale(0);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes scaled-reverse {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}