.family-tree {
  .page-body {
    border-radius: $round-md !important;
    border: 1px solid $neutral-300;
    padding: 0 !important;

    @include media-breakpoint-down(sm) {
      border: none;
    }

    @include flexible-value((
      prop: margin-right,
      value: 28px,
      value-sm: 0,
    ));

    @include flexible-value((
      prop: margin-left,
      value: 28px,
      value-md: 20px,
      value-sm: 12px,
    ));

    @import "tree-card";
  }
}
