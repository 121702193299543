.popup-access-mini {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  border-radius: $round-md;

  @include flexible-value((
    prop: padding,
    value: (95px 4px),
    value-xs: (10px 4px),
  ));

  &.gradient {
    background: linear-gradient(136.53deg, $white 46.44%, $primary-300 109.04%);
    box-shadow: 0px 0px 4px rgba(40, 37, 49, 0.04), 0px 0px 16px rgba(40, 37, 49, 0.04);

    &.large {
      @include media-breakpoint-up(xs) {
        background: linear-gradient(92.16deg, $white 48.84%, $primary-300 119.81%),
        linear-gradient(0deg, $white, $white);
        box-shadow: $box-shadow;
      }
    }
  }

  &.large {
    @include flexible-value((
      prop: padding,
      value: (24px 59px 24px 33px),
      value-xs: (24px 24px),
    ));

    @include flexible-value((
      prop: flex-direction,
      value: row,
      value-xs: column,
    ));

    .popup-access-mini-container {

      @include flexible-value((
        prop: margin-right,
        value: 30px,
        value-xs: unset,
      ));

      @include flexible-value((
        prop: margin-bottom,
        value: unset,
        value-xs: 32px,
      ));

      .popup-access-mini-title {
        @include flexible-value((
          prop: text-align,
          value: left,
          value-xs: center,
        ));

        @include flexible-value((
          prop: white-space,
          value: pre-wrap,
          value-xs: normal,
        ));

      }

      .popup-access-mini-text {
        padding: 0;
        margin: 0;

        @include flexible-value((
          prop: text-align,
          value: left,
          value-xs: center,
        ));

        @include flexible-value((
          prop: white-space,
          value: pre-wrap,
          value-xs: normal,
        ));
      }
    }

    .btn-upgrade {
      align-self: center;
      margin: 0;

      @include flexible-value((
        prop: width,
        value: 220px,
        value-xs: 100%,
      ));
    }
  }

  @include media-breakpoint-down(md) {
    width: 100%;
  }

  .popup-access-mini-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .popup-access-mini-title {
    margin: 0;
    text-align: center;
    color: $martinique;

    @include poppins-l-font;
  }

  .popup-access-mini-text {
    padding: 0;

    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    text-align: center;
    color: $neutral-500;

    @include flexible-values((
      prop: padding,
      directions: (top bottom left right),
      values: (0, 0, 0, 0),
      values-xs: (0, 0, 50px, 50px),
    ));
  }

  .btn-upgrade {
    margin-top: 16px;
    padding: 12px 20px;
    height: 48px;
    width: 100%;
    min-height: min-content;
    align-self: center;
    border-radius: $round-md;
    box-sizing: border-box;

    @include flexible-value((
      prop: width,
      value: 100%,
      value-xs: auto,
    ));

    @include poppins-m-font;
  }
}
