.profile {
  .profile-avatar {
    width: 76px !important;
    height: 76px !important;
    margin-right: 24px;
    border-radius: $round-md;
    object-fit: cover;

    @include media-breakpoint-down(xs) {
      width: 63px !important;
      height: 63px !important;
    }
  }

  .profile-greeting {
    margin-bottom: 3px;

    .profile-greeting-span {
      color: $primary;
    }

    @include profile-name-font;
  }

  .profile-info {
    display: flex;
    flex-wrap: wrap;
    padding: 0 28px;

    margin-top: 20px;

    @include flexible-values((
      prop: padding,
      directions: (right left top bottom),
      values: (24px, 24px, 0, 0),
      values-sm: (12px, 12px, 0, 0),
    ));

    .profile-info-text {
      flex: 1 0 65%;

      @include media-breakpoint-down(sm) {
        flex: 1 0 40%;
      }
    }

    .profile-email {
      @include flexible-default-font-size;
    }
  }

  .profile-group-list {
    display: flex;
    flex-wrap: wrap;
    margin: 24px 0 0 0;
    list-style: none;
    gap: 8px;

    @include flexible-value((
      prop: margin-bottom,
      value: 60px,
      value-lg: 40px,
      value-sm: 30px,
    ));

    @include flexible-values((
      prop: padding,
      directions: (right left top bottom),
      values: (24px, 24px, 0, 0),
      values-sm: (12px, 12px, 0, 0),
    ));

    .profile-group-list-item {
      padding: 4px 12px;
      background-color: $white;
      border-radius: $round-md;
      white-space: nowrap;

      @include label-font-size;
    }
  }

  .profile-aside {
    display: flex;
    flex-direction: column;
    background: $white;
    gap: 16px;

    @include media-breakpoint-down(md) {
      flex: 0;
      max-width: 100%;
      margin-bottom: 20px;
    }
  }

  .profile-container {
    flex: 1;
  }

  .form {
    margin-bottom: 28px;

    .form-heading {
      display: block;
      margin-top: 12px;

      @include poppins-m-font;

      @include flexible-value((
        prop: margin-bottom,
        value: 26px,
        value-lg: 28px,
        value-sm: 32px,
      ));
    }

    &.change-form {
      .form-group {
        width: 100%;
      }
    }
  }
}

.layout {
  .layout-content {
    padding-right: 24px;

    .tab-content,
    .page-body {
      border-radius: 8px !important;
    }

    .profile {
      .tab-content {
        width: 100%;
        max-width: 1262px;
      }

      .page-body {
        display: grid;
        flex: 1 1 70% !important;
        grid-template-columns: minmax(0, 900px) 269px;
        grid-template-rows: repeat(3, min-content);
        background-color: $white;
        justify-content: flex-start;
        gap: 0 32px;

        .loading {
          @include flexible-value((
            prop: grid-area,
            value: 3,
            value-sm: 2,
          ));
        }

        .personal-form {
          grid-area: 1 / 1 / 2 / 2;
        }

        .breeder-form {
          grid-area: 1 / 1 / 4 / 2;

          .custom-header {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .custom-checkbox-label {
              margin-left: 14px;
              color: $neutral-900 !important;
              @include inter-sm-font;
            }
          }
        }

        .change-form {
          margin-bottom: 0;
          grid-area: 4 / 1 / 5 / 2;
        }

        .profile-aside {
          grid-area: 1 / 2 / 5 / 2;
        }

        .show-form {
          grid-area: 6 / 1;
        }

        .delete-account-btn {
          grid-area: 7 / 1;
        }

        @include media-breakpoint-down(md) {
          grid-template-columns: minmax(0, 1fr);
          grid-template-rows: repeat(4, min-content);
          gap: 24px 0;

          .personal-form {
            grid-area: 1 / 1 / 2 / 2;
          }

          .breeder-form {
            grid-area: 1 / 1 / 1 / 1;
          }

          .change-form {
            grid-area: 4 / 1 / 6 / 2;
          }

          .show-form {
            grid-area: 6 / 1 / 6 / 2;
            margin-bottom: 0;
          }

          .achievements-list {
            grid-area: 2 / 1 / 3 / 2;

            .achievement-items {
              flex-direction: row;
              column-gap: 8px;
              row-gap: 8px;

              .achievement-item {
                width: auto;

                .image-container {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  width: 64px;
                  height: 64px;
                  background-color: #fff1d9;
                  border-radius: 50%;

                  img {
                    width: 32px;
                    height: 32px;
                  }
                }
              }
            }
          }

          .profile-aside {
            grid-area: 3 / 1 / 4 / 2;
          }
        }
      }
    }

    @include media-breakpoint-down(sm) {
      padding-right: 0;
    }
  }
}

@import 'achievements';
@import 'control';
