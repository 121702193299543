.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 48px;
  padding: 12px 16px;
  border-radius: $round-md;
  white-space: nowrap;

  .spinner {
    width: 24px;
    height: 24px;
  }

  @include poppins-m-font;

  &.btn-primary {
    background-color: $primary;
    border-color: $primary;
    color: $neutral-900;
    font-weight: 500;
    transition: background-color .2s ease, box-shadow .2s ease;

    &:not(.danger-btn):not(:disabled):hover {
      box-shadow: $primary-btn-focus-shadow;
      background-color: $primary-600;
    }

    &:not(.danger-btn):not(:disabled):focus {
      box-shadow: $primary-btn-focus-shadow;
      background-color: $primary-600;
    }

    &:not(.danger-btn):not(:disabled):active {
      background-color: $primary-700;
    }
  }

  &.delete {
    path {
      transition: stroke .2s ease;
    }
   
    &:hover {
      path {
        stroke: $error-500;
      }
    }
  }

  &.info {
    padding: 0 0 0 12px;

    path {
      transition: stroke .2s ease;
    }

    &:hover {
      path {
        stroke: $primary-600;
      }
    }

    &:focus {
      box-shadow: none;
    }

    &.active {
      path {
        stroke: $primary-600;
      }
    }
  }

  &.danger-btn {
    background-color: $error-500;
    border-color: $error-500;
    color: $white;

    &:hover {
      background-color: $error-600;
    }

    &:focus {
      background-color: $error-600;
    }

    &:active {
      background-color: $error-600;
    }
  }

  &.btn-secondary {
    border: 0;
    outline: 0;
    color: $neutral-900;
    font-family: $font-family-base;
    font-weight: 400;
    transition: color .2s ease;

    &:hover {
      box-shadow: none;
      background-color: transparent;
      border: 0;
      outline: 0;
      color: $primary;
    }

    &:focus {
      box-shadow: none;
      background-color: transparent;
      border: 0;
      outline: 0;
      color: $primary;
    }

    &:active {
      box-shadow: none;
      background-color: transparent;
      border: 0;
      outline: 0;
      color: $primary;
    }
  }

  &.btn-link {
    font-family: $font-family-base, $secondary-font;
    @include label-font-size;
    color: $link-font-color;
  }

  &.translucent {
    background-color: #fff8;
    border-color: #fffa;

    &:focus,
    &:hover {
      background-color: #fffc;
    }
  }

  &.btn-transparent {
    background-color: transparent;
    border-color: transparent;
  }

  &.clear-btn {
    height: auto;
    padding: 0;
  }
  
  &.create-btn{
    padding: 12px 16px 12px 42px;

    .create-btn-text {
      position: relative;

      &::after {
        position: absolute;
        top: 46%;
        left: -25px;
        width: 12px;
        height: 12px;
        background-image: url('/icons/plus.svg');
        transform: translateY(-50%);
        content: '';
      }
    }

    &:not(.empty) {
      @include flexible-value((
        prop: min-width,
        value: 167px,
        value-xs: 100%,
      ));
    }
  }
}

.page-body {
  .btn[type='submit'] {
    min-width: 150px;
    margin-left: auto;
  }
}

.layout {
  .layout-content {
    .page-body {
      .date-picker{
        .btn {
          background-color: $primary;
          border-color: $primary;
          color: $neutral-900;
          font-weight: 500;
          transition: background-color .2s ease, box-shadow .2s ease;
      
          &:hover {
            box-shadow: $primary-btn-focus-shadow;
            background-color: $primary-600;
          }
      
          &:focus {
            box-shadow: $primary-btn-focus-shadow;
            background-color: $primary-600;
          }
      
          &:active {
            background-color: $primary-700;
          }
        }
      }
    }
  }
}



.btn-group {
  padding: 0;

  .dropdown-toggle-split {
    padding: 6px auto;

    @include flexible-values((
      prop: padding,
      directions: (right left),
      values: (30px, 15px),
      values-lg: (15px, 7.5px),
    ));


    &:after {
      border: 0;
      font-size: 20px;
      content: '⋮';
    }
  }

  .edit-button:not(.dropdown-toggle) {
    padding: 6px auto;

    @include flexible-values((
      prop: padding,
      directions: (right left),
      values: (15px, 30px),
      values-lg: (7.5px, 15px),
    ));
  }
}

.btn[type="submit"]{
  @include media-breakpoint-down(xs) {
    width: 100%;
  }
}

.btn-transparent:not(:disabled):not(.disabled):active, 
.btn-transparent:not(:disabled):not(.disabled).active, 
.show > .btn-transparent.dropdown-toggle {
  background-color: transparent;
  border: 0;
}

.btn-transparent:not(:disabled):not(.disabled):active:focus, 
.btn-transparent:not(:disabled):not(.disabled).active:focus, 
.show > .btn-transparent.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-primary:not(:disabled):not(.disabled):active, 
.btn-primary:not(:disabled):not(.disabled).active, 
.show > .btn-primary.dropdown-toggle {
  background-color: $primary-700;
  border-color: $primary-700;
}

.btn-primary:not(:disabled):not(.disabled):active:focus, 
.btn-primary:not(:disabled):not(.disabled).active:focus, 
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: $primary-focus-shadow;
}

.btn-light:not(:disabled):not(.disabled):active, 
.btn-light:not(:disabled):not(.disabled).active, 
.show > .btn-light.dropdown-toggle {
  background-color: transparent;
  border: 0;
  color: $primary-700;
}

.btn-light:not(:disabled):not(.disabled):active:focus, 
.btn-light:not(:disabled):not(.disabled).active:focus, 
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: none;
}

.am-dropdown-button.btn-group {
  .btn.am-dropdown-toggle {
    padding: 0 10px;
    border-radius: $round-lg;
  }
}

.danger-btn:not(:disabled):not(.disabled):active, 
.danger-btn:not(:disabled):not(.disabled).active, 
.show > .danger-btn.dropdown-toggle {
  box-shadow: none;
  background-color: $error-700;
  border-color: $error-700;
  color: $white;
}

.btn-custom {
  background-color: $primary-200;
  border: 1px solid $primary !important;
  border-radius: $round-md;
  padding: 12px 24px 12px 48px !important;
  position: relative;

  &.edit-btn {
    &::before {
      position: absolute;
      top: 24px;
      left: 15px;
      width: 19px;
      height: 19px;
      background-image: url('/icons/pencil-edit.svg');
      background-repeat: no-repeat;
      transform: translateY(-50%);
      content: '';
    }

    @include media-breakpoint-down(sm) {
      padding: 12px 24px 12px 24px !important;

      .btn-text {
        display: none;
      }
    }
  }

  &.transfer-btn {
    &::before {
      position: absolute;
      top: 24px;
      left: 15px;
      width: 19px;
      height: 19px;
      background-image: url('/icons/transfer.svg');
      background-repeat: no-repeat;
      transform: translateY(-50%);
      content: '';
    }

    @include media-breakpoint-down(sm) {
      padding: 12px 24px 12px 24px !important;

      .btn-text {
        display: none;

        @include poppins-m-font;
      }
    }
  }

  &.members-btn {
    &::before {
      position: absolute;
      top: 24px;
      left: 15px;
      width: 22px;
      height: 18px;
      background-image: url('/icons/members.svg');
      background-repeat: no-repeat;
      transform: translateY(-50%);
      content: '';
    }

    @include media-breakpoint-down(sm) {
      padding: 12px 24px 12px 26px !important;

      .btn-text {
        display: none;
      }
    }
  }

  &:hover {
    color: $neutral-900 !important;
    background-color: $primary !important;
  }

  &:active {
    color: $neutral-900 !important;
    background-color: $primary-600 !important;
  }

  &:focus {
    color: $neutral-900 !important;
    background-color: $primary-600 !important;
  }
}
.logout-container {
  .logout-btn {
    min-width: 105px;
    min-height: 50px;
    border-radius: 5px;
    text-align: center;
    letter-spacing: 0;

    @include poppins-m-font;
  }

  .logout-trial {
    padding: 0;
    background: transparent;
    font-family: $secondary-font, $font-family-base;
    font-weight: 500;
    font-size: 16px !important;
    line-height: 18px !important;
    color: $gray-500;
    min-width: initial;
    min-height: initial;
    margin-left: 8px;
  }

  &.trial-container {
    display: flex;
    text-align: center;
    justify-content: center;
    flex-direction: row;
    cursor: pointer;

    @include flexible-value((
      prop: margin-bottom,
      value: 27px,
      value-sm: 24px,
    ));

    &:hover {
      .logout-trial {
        color: #EF4444;
      }

      svg {
        path {
          stroke: #EF4444;
        }
      }
    }
  }
}
