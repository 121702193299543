.body-upgrade {
  padding: 0 48px 40px !important;
  display: flex;
  flex-direction: column;
  align-items: center;

  .title {
    margin: 0;
    @include poppins-l-font()
  }

  .description {
    margin: 0;
    padding: 24px 0 40px;
    @include inter-m-font()
  }

  .upgrade-btn {
    margin-top: auto;
  }
}