.herd-card {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  height: 184px;
  background: $primary;
  border: #eee 1px solid;
  border-radius: $round-lg;
  text-decoration: none;
  transition: $transition-base;
  overflow: hidden;

  @include media-breakpoint-down(sm) {
    max-width: none;
  }

  &:focus,
  &:hover {
    box-shadow: $shadow-sm;
    transform: scale(1.01);
  }

  &:active {
    transform: scale(0.99);
  }

  .herd-image {
    flex-grow: 1;
    background: center / cover no-repeat;
    overflow: auto;

    span {
      img {
        aspect-ratio: 2 / 1;
        object-fit: cover;
      }
    }
  }

  .herd-name {
    display: flex;
    align-items: center;
    width: 100%;
    min-height: 54px;
    padding-left: 16px;
    background-color: #fff;
    color: $neutral-900;

    @include inter-m-font;
  }

  @media (max-width: 480px) {
    width: auto;
    height: 55.5vw;
  }
}