.input-group-prepend {
  display: flex;
  padding-right: 2px !important;
  max-width: fit-content;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: 0 !important;
  align-items: center;
  flex-grow: 3 !important;

  &.is-invalid-input {
    box-shadow: none !important;
    border-color: #FF5252 !important;
  }

  &:hover {
    border-color: #FFD07A;
  }
}