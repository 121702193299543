.editor-wrapper {
  margin: 0 0 20px -15px;
  padding: 0 15px;
  width: 100%;
  display: flex;
  flex-direction: column-reverse;

  &.editor-focus {
    .editor-toolbar {
      border: 1px solid $primary;
      border-top: 1px solid $neutral-150;
      box-shadow: -1px 1px 0 $primary, 
      1px 1px 0 $primary;
    }

    .editor {
      border: 1px solid $primary;
      border-bottom: none;
      box-shadow: -1px -1px 0 $primary, 
      1px -1px 0 $primary;
    }
  }

  .editor {
    min-height: 100px;
    padding: 0 10px;
    border: 1px solid $neutral-300;
    border-bottom: none;
    border-radius: $round-md;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    transition: border-color 0.15s ease-in-out;
  }

  .editor-focus {
    border: 1px solid $primary;
    box-shadow: $primary-focus-shadow;
  }

  .editor-toolbar {
    margin: 0;
    width: 100%;
    padding: 0;
    justify-content: center;
    border: 1px solid $neutral-300;
    border-radius: $round-md;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-top: 1px solid $neutral-150;
    z-index: 3;
    
    .editor-option-wrapper {
      margin: 0;
      flex-wrap: nowrap;
    }

    .rdw-option-disabled {
      opacity: 1;

      path {
        fill: $neutral-300;
      }
    }

    .rdw-image-wrapper {
      margin: 0;
    }

    .rdw-option-wrapper {
      margin: 0;
      padding: 0;
      width: 40px;
      height: 40px;
      border: 0;
      border-right: 1px solid $neutral-150;
      background: transparent;

      &[title="Image"] {
        border: 0;
      }

      &.rdw-option-active {
        box-shadow: none;

        path {
          fill: $primary-700;
        }
      }

      path {
        transition: fill .2s ease;
      }

      &:hover {
        box-shadow: none;

        &:not(.rdw-option-active):not(.rdw-option-disabled) {
          path {
            fill: $primary;
          }
        }
      }
    }
    
    .rdw-image-modal, 
    .rdw-link-modal {
      padding: 24px;
      width: 276px;
      border-radius: $round-md;
      box-shadow: $box-shadow;
      border: 0;
      height: auto;

      @include label-font-size;

      .rdw-link-modal-label,
      .rdw-image-modal-label {
        @include label-font-size;
      }

      .rdw-image-modal-url-input,
      .rdw-link-modal-input, 
      .rdw-image-modal-size-input {
        padding: 7px 13px;
        height: auto;
        border-radius: $round-md;
        border: 1px solid $neutral-200;

        @include label-font-size;
      }

      .rdw-link-modal-label,
      .rdw-image-modal-label {
        margin: 0;
        position: absolute;
        background-color: $white;
        padding: 0 4px;

        &[for='linkTitle'] {
          top: 18px;
          left: 36px;
        }

        &[for='linkTarget'] {
          top: 77px;
          left: 36px;
        }
      }

      .rdw-link-modal-buttonsection {
        margin: 0;
        display: flex;
        justify-content: space-between;
        gap: 15px;
      }

      .rdw-image-modal-btn-section {
        margin: 30px 0 0;
        display: flex;
        justify-content: space-between;
        gap: 15px;
      }

      .rdw-image-modal-size {
        margin: 0;
      }

      .rdw-image-modal-size-input[name='height'] {
        margin-right: 20px;
      }

      .rdw-link-modal-btn, 
      .rdw-image-modal-btn {
        margin: 0;
        width: 100%;
        border-radius: $round-md;
        box-shadow: none;
        transition: background .2s ease, box-shadow .2s ease;
        
        &[disabled] {
          cursor: default;
        }

        &:first-child {
          border: 1px solid $primary;
          background: $primary;

          &:not([disabled]):hover {
            background-color: $primary-600;
            box-shadow: $primary-btn-focus-shadow;
          }
      
          &:not([disabled]):focus {
            background-color: $primary-600;
            box-shadow: $primary-btn-focus-shadow;
          }
      
          &:not([disabled]):active {
            background-color: $primary-700;
          }
        }

        &:last-child {
          border: 1px solid $neutral-200;
          background: $neutral-200;

          &:hover {
            background: $neutral-300;
            border: 1px solid $neutral-300;
          }
      
          &:focus {
            background: $neutral-300;
            border: 1px solid $neutral-300;
          }
      
          &:active {
            background: $neutral-500;
            border: 1px solid $neutral-500;
            color: $white;
          }
        }
      }

      .rdw-image-modal-header {
        margin: 0;
        position: absolute;
        width: 32px;
        top: 15px;
        left: 34px;

        @include label-font-size;

        .rdw-image-modal-header-option {
          background: $white;
          width: 32px;
          padding: 0 4px;
        }
      }

      .rdw-image-modal-url-input {
        margin: 0 0 16px;
        width: 100%;
      }

      .rdw-image-modal-header-label,
      .rdw-image-mandatory-sign,
      .rdw-link-modal-target-option{
        display: none;
      }
    }

    .editor-option-wrapper-image {
      border: 0;
    }
  }

  .editor-logo {
    width: 28px;
    height: 25px;
  }
}

.public-DraftEditorPlaceholder-root {
  color: $silver-gray;
}
