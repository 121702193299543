.upgrade-section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 385px;

  .title {
    text-align: center;
    margin-bottom: 16px;
  }

  .description {
    margin-bottom: 40px;
    text-align: center;

    @include upgrade-message-hint();
  }
}