$pale-gray: #fafafa;
$light-gray: #f2f2f2;
$silver-gray: #dedede;
$bone-gray: #e7e7e7;
$dusty-gray: #999;
$whisper-gray: #eee;
$neutral-500-glass: #ccc3;
$dark-gray: #666;
$light-gray: #f2f2f2;
$martinique: #2c2543;
$pale-sky: #667180;
$white-lilac: #f6f7fb;
$manatee: #9197a3;
$mercury: #E8E8E8;
$wild-sand: #F7F7F7;

$link-font-color: #1473E6;

$error-100: #FFE8E3;
$error-500: #FF5252;
$error-600: #E61414;
$error-700: #BC1010;

$red-600: #DC2626;

$positive-100: #DEF7E7;
$positive-500: #4EE02A;
$positive-700: #28AE56;

$green-600: #16A34A;

$gray-50: #F9FAFB;
$gray-100: #F3F4F6;
$gray-200: #E5E7EB;
$gray-300: #D1D5DB;
$gray-400: #9CA3AF;
$gray-500: #6B7280;
$gray-700: #374151;
$gray-800: #1F2937;
$gray-900: #111827;

$warm-gray-50: #FAFAF9;
$warm-gray-100: #F5F5F4;
$warm-gray-200: #E7E5E4;
$warm-gray-300: #D6D3D1;
$warm-gray-400: #A8A29E;
$warm-gray-600: #57534E;
$warm-gray-500: #78716C;
$warm-gray-900: #1C1917;

$blue-500: #3B82F6;
$blue-600: #2563EB;
$blue-800: #1E40AF;

$neutral-100: #FFFFFF;
$neutral-150: #F7F7F7;
$neutral-200: #E8E8E8;
$neutral-300: #D4D4D4;
$neutral-500: #8F8F8F;
$neutral-800: #666666;
$neutral-900: #282531;

$shadow-md: 2px 2px 10px 0 rgba(0, 0, 0, 0.15);
$shadow-sm: 1px 2px 10px rgba(0, 0, 0, 0.1);

$round-sm: 4px;
$round-md: calc(#{$round-sm} * 2);
$round-lg: calc(#{$round-sm} * 3);
$round-xl: calc(#{$round-sm} * 4);

$box-shadow: 0px 0px 24px -4px rgba(44, 37, 67, 0.08), 0px 0px 12px -6px rgba(44, 37, 67, 0.08);
$in-valid: rgba(220, 53, 69, 0.25);

$whisper-gray-hover: darken($whisper-gray, 5);
$primary-hover: desaturate(darken($primary, 5), 20);
$primary-opacity: transparentize($primary, 0.6);
$primary-darken: darken($primary, 48);

$font-family-base: 'Inter',
-apple-system,
'BlinkMacSystemFont',
'Segoe UI',
'Roboto',
'Helvetica Neue',
'Arial',
'Noto Sans',
'Liberation Sans',
'sans-serif',
'Apple Color Emoji',
'Segoe UI Emoji',
'Segoe UI Symbol',
'Noto Color Emoji';
$secondary-font: 'Poppins';

$input-height: calc(2em + 0.75rem + 2px);
$input-border-radius: 6px;
$primary-focus-shadow: 0px 0px 0px 1px $primary;
$primary-btn-focus-shadow: 0px 4px 6px -4px transparentize($primary, 0.8), 0px 8px 8px -4px transparentize($primary, 0.8);
$secondary-btn-focus-shadow: 0px 0px 4px -2px transparentize($neutral-900, 0.88), 0px 0px 16px -2px transparentize($neutral-900, 0.88);
$secondary-focus-border: 1px solid $neutral-500;

$modal-content-border-radius: 12px;