.upload-image-modal-body {
  &.custom-modal-body {
    align-items: center;
    border: 1px dashed $primary;
    border-radius: $round-md;
    cursor: pointer;

    .image-preview {
      width: 100%;
      height: 100%;

      .drop-area {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        vertical-align: middle;
        width: 100%;
        height: 88px;

        .custom-load-text {
          color: $neutral-800;
          @include poppins-sm-font;

          &:not(.new-image) {
            &::before {
              position: absolute;
              width: 12px;
              height: 12px;
              top: 37px;
              right: 70%;
              background-color: $neutral-800;
              -webkit-mask-image: url('/icons/plus.svg');
              mask-image: url('/icons/plus.svg');
              background-repeat: no-repeat;
              content: '';
            }
          }

          &.new-image {
            &::before {
              position: absolute;
              width: 14px;
              height: 14px;
              top: 37px;
              right: 72%;
              background-color: $neutral-800;
              -webkit-mask-image: url('/icons/refresh.svg');
              mask-image: url('/icons/refresh.svg');
              background-repeat: no-repeat;
              content: '';
            }
          }
        }

        &.drag-active {
          background-color: $primary-200;
          border-radius: $round-md;
        }
      }

      .crop-area {
        &.drag-active {
          border-radius: $round-md;
          background-color: $primary-200;
          border: 1px dashed $primary;
        }
      }

      &:hover {
        background-color: $primary-200;
        border-radius: $round-md;
      }

      &.cropper-image {
        &:hover {
          background-color: unset !important;
        }
      }
    }
  }


  width: 100%;

  .image-preview {
    position: relative;

    .dropbox-btn {
      width: 100%;
      height: 200px;
      transition: all 0.2s ease-in-out;

      &:not(.compact) {
        &:hover,
        &:focus {
          background-color: $primary-400;
        }
      }

      &.compact {
        height: 50px;
      }
    }

    .image-cropper {
      padding-top: 20px;
    }
  }

  .upload-image-btns {
    display: flex;
    justify-content: flex-end;
    padding-top: 20px;
  }
}

.image-cropper {
  width: 100%;
  height: 100%;

  .image-cropper-wrap {
    position: relative;
    width: 100%;
    padding-top: 100%;
    border-radius: $round-md;

    .cropper-loading {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 999;
      width: 100%;
      height: 100%;
      background-color: #fff;
    }

    .image-cropper-container {
      .image-cropper-crop-area {
        border-color: #eee;

        &::before,
        &::after {
          border-color: #eee;
        }
      }
    }
  }

  .image-cropper-controls {
    padding: 10px 0;

    input.form-control-range {
      width: 100%;
      height: 20px;
      -webkit-appearance: none;

      &::-webkit-slider-thumb {
        width: 28px;
        height: 20px;
        margin-top: -6px;
        box-shadow: 1px 1px 1px #aaaa;
        background: $primary-600;
        border: 1px solid $primary-700;
        border-radius: $round-lg;
        cursor: pointer;
        -webkit-appearance: none;
      }

      &::-webkit-slider-runnable-track {
        width: 100%;
        height: 10px;
        background: $primary-400;
        border: 1px solid $primary;
        border-radius: $round-sm;
        cursor: pointer;
      }
    }
  }
}
