.table-content-wrap {
  width: 100%;
  background-color: transparent;

  .table-wrap {
    width: 100% !important;
    border-radius: 8px;
    background-color: inherit;
    border: 1px solid $neutral-200;
    overflow: auto;
    box-shadow: $box-shadow;

    table {
      min-width: 100%;
      // width: 100%;
      table-layout: fixed;

      tr > td, th {
        padding: 0;
        margin: 0;

        .header-cell-wrap, .cell-wrap {
          min-width: 50px;
          padding: 0 16px;
          white-space: nowrap;

          .actions-cell {
            .action {
              &.cancel {
                padding: 0;
                @include inter-m-font;
                color: $error-500;

                &:focus {
                  outline: none !important;
                  box-shadow: none !important;
                }
              }

              &.accept {
                padding: 0;
                @include inter-m-font;
                color: #5DBA39;

                &:focus {
                  outline: none !important;
                  box-shadow: none !important;
                }
              }

              &.reject {
                padding: 0;
                @include inter-m-font;

                font-weight: 400 !important;
                color: $error-500;

                &:focus {
                  outline: none !important;
                  box-shadow: none !important;
                }
              }
            }
          }
        }

        &:first-child {
          .header-cell-wrap, .cell-wrap {
            padding-left: 28px;   
          }
        }

        &:last-child {
          .header-cell-wrap, .cell-wrap {
            padding-right: 28px;   
          }
        }
      }

      thead {
        position: sticky;
        top: 0;
        background-color: $neutral-150;
        border-bottom: 1px solid $neutral-200;
    
        tr {
          &:not(:last-child) {
            border-bottom: 1px solid $neutral-100; 
          }

          th {
            .header-cell-wrap {
              display: flex;
              align-items: center;
              padding-top: 11px;
              padding-bottom: 11px;
  
              color: $neutral-800;
              font-style: normal;
              font-weight: normal;
              font-size: 12px;
              line-height: 20px;

              .sort-direction-icon {
                display: flex;
                flex-direction: column;
                width: 8px;
                margin: 0;
                margin-left: 8px;
              
                .sort-direction-asc {
                  transform: rotate(180deg);
                }
              
                .sort-direction-desc {
                  margin-top: 2px;
                }
              
                svg[class*='sort-direction-'] {
                  path {
                    transition: stroke 0.2s;
                  }
              
                  &.active path {
                    stroke: #666; // Neutral/800
                  }
                }
              }
            }
          }
        }
      }
    
      tbody {
        background-color: #fff; // Neutral/100
    
        tr {
          &.clickable {
            cursor: pointer;
          }

          td {
            .cell-wrap {
              display: flex;
              align-items: center;
              width: 100%;
              height: 100%;
              min-height: 76px;
              padding-top: 12px;
              padding-bottom: 12px;

              // temporary
              color: #001E32; // Neutral/900
              font-style: normal;
              font-weight: normal;
              font-size: 16px;
              line-height: 140%;
            }
          }
    
          &:not(:last-child) {
            border-bottom: 1px solid #E8E8E8; // Neutral/200
          }
        }
      }
    }
  }
}