html {
  scrollbar-gutter: stable;
}

a {
  color: $neutral-900;
  text-decoration: none;

  &:focus,
  &:hover,
  &:active {
    color: $neutral-900;
    text-decoration: none;
  }
}

html,
body,
#root,
.app {
  height: 100%;
  font-family: $font-family-base;
}

.page {
  display: flex;
  flex-direction: column;
}

.flex {
  display: flex;
}

.flex-end {
  justify-content: flex-end;
}

.center {
  align-items: center;
  justify-content: center;
}

.margin-left-xs {
  margin-left: 15px;
}

.margin-bottom-s {
  @include flexible-value(
    (
      prop: margin-bottom,
      value: 22px,
      value-lg: 17px,
      value-sm: 14px,
    )
  );
}

.cursor-pointer {
  cursor: pointer;
}

.count-hint {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $primary;
  border-radius: 50%;

  @include flexible-default-font-size;

  @include flexible-value(
    (
      prop: width,
      value: 28px,
      value-sm: 22px,
    )
  );

  @include flexible-value(
    (
      prop: height,
      value: 28px,
      value-sm: 22px,
    )
  );
}

.react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
  display: none;
}

.react-horizontal-scrolling-menu--scroll-container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.form-checkbox {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.custom-link {
  font-family: $font-family-base !important;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #1473e6;

  &:hover,
  &:active,
  &:focus {
    color: #1473e6;
    text-decoration: underline;
  }
}

.tooltip {
  max-width: 250px;
}
