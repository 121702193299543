.logo {
  display: flex;
  flex-direction: row;
  align-items: center;

  @include flexible-value((
    prop: flex,
    value: 0 1 auto,
    value-xs: 1,
  ));


  .logo-title {
    font-family: $secondary-font, $font-family-base;
    font-weight: 600;
    font-size: 18px;
    line-height: 19px;
    margin: 0 0 0 12px;
    color: $gray-900;
  }
}
