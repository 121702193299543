.edge-labels {
  z-index: auto;
  height: auto;

  text {
    @include inter-sm-font();
    padding: 0;
  }

  rect {
    stroke: #D4D4D4;
  }

  &.dna-verified {
    rect {
      stroke: #6CD743;
    }
  }

  &.claimed, &.challenged {
    rect {
      stroke: #FFC04C;
    }
  }
}