.control-accordion {
  border: 1px solid $warm-gray-200;
  background: $white;

  &.isOpen {
    background: $warm-gray-50;
  }

  &.disabled {
    .control-accordion {
      &-header {
        &__title {
          color: $warm-gray-600;
        }

        &__chevron,
        &__icon {
          color: $warm-gray-400;
        }
      }
    }
  }

  &-header {
    width: 100%;
    border: none;
    background: none;

    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 24px;

    &__content {
      display: flex;
      align-items: center;
    }

    &__icon,
    &__chevron {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 30px;

      color: $warm-gray-900;

      &.green {
        color: $green-600;
      }

      &.grey {
        color: $warm-gray-400;
      }

      &.red {
        color: $red-600;
      }
    }

    &__title {
      margin: 0;
      margin-left: 6px;
      color: $warm-gray-900;

      font-size: 20px;
      font-family: Inter;
      font-weight: 600;
      line-height: 30px;
    }

    &__toggle {
      margin-left: 12px;
    }
  }

  &-body {
    padding: 0 16px 24px 62px;
  }

  @include media-breakpoint-down(sm) {
    &-header {
      padding: 12px 20px;
    }

    &-body {
      padding: 0 12px 24px;
    }
  }
}
