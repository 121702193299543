.fallback {
  width: 100%;
  height: 100%;
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .fallback-message {
    font-size: 40px;
    line-height: 51px;
    text-align: center;
  }

  .fallback-span {
    display: block;
    font-size: 25px;
    opacity: 0.8;
  }

  .btn {
    min-width: 50px;
    min-height: 50px;
    background-image: url('/icons/refresh.svg');
    background-repeat: no-repeat;
    background-position: center;
  }
}
