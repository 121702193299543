.control-page {
  &-accordions {
    margin-top: 16px;

    .control-accordion:first-child {
      border-radius: 8px 8px 0 0;
    }

    .control-accordion:last-child {
      border-radius: 0 0 8px 8px;
    }

    .control-accordion + .control-accordion {
      border-top: none;
    }
  }

  @include media-breakpoint-down(sm) {
    &-accordions {
      margin-top: 8px;
    }
  }
}

/* Editor + Input styles */
.control-page {
  .editor-wrapper {
    margin: 0;
    padding: 0;
    background-color: #fff;

    /* Hover Effect */
    &:hover {
      .editor-toolbar {
        border: 1px solid $primary;
        border-top: 1px solid $warm-gray-300;
      }

      .editor {
        border: 1px solid $primary;
        border-bottom: none;
      }
    }

    /* Focus/Hover */
    &.editor-focus {
      .editor-toolbar {
        border-top: 1px solid $warm-gray-300;
      }
    }

    /* Disabled */
    &.editor-disabled {
      .editor-toolbar,
      .editor {
        cursor: not-allowed;
        border: 1px solid $warm-gray-200;
        background: $warm-gray-100;
      }

      .editor {
        border-bottom: none;

        span {
          color: $warm-gray-500;
          background: $warm-gray-100;
        }
      }

      .editor-option-wrapper,
      .rdw-option-wrapper,
      .rdw-option-active {
        cursor: not-allowed;
        color: $warm-gray-500;

        path {
          fill: $warm-gray-500 !important;
        }
      }
    }

    /* Default */
    .editor-toolbar {
      border-top: 1px solid $warm-gray-300;

      .editor-option-wrapper,
      .rdw-option-wrapper {
        flex-wrap: nowrap;
        flex-shrink: 1;
        flex-grow: 1;
        width: unset;
      }

      .editor-option-wrapper {
        flex-basis: 28.5%;

        &:first-child {
          flex-basis: 42.75%;
        }

        .rdw-option-wrapper {
          border-right: 1px solid $warm-gray-300;
        }

        &:last-of-type {
          .rdw-option-wrapper:last-of-type {
            border-right: none;
          }
        }
      }
    }
  }

  .react-custom-select--is-disabled {
    .react-custom-select__control {
      cursor: not-allowed;
      border: 1px solid $warm-gray-200;
      background: $warm-gray-100;
    }

    .react-custom-select__multi-value__label,
    .react-custom-select__multi-value__remove {
      background: $warm-gray-200;
      color: $warm-gray-500;
    }
  }

  .form-group {
    margin-bottom: 0;
  }

  .public-DraftEditor-content,
  .form-control {
    color: $warm-gray-900;
    font-size: 16px;
    font-family: Inter;
    line-height: 140%;
  }

  .public-DraftEditorPlaceholder-root,
  .form-control::placeholder {
    color: $warm-gray-500;
    font-size: 16px;
    font-family: Inter;
    line-height: 140%;
  }
}

@import './live';
@import './field';
@import './accordion';
@import './table';
