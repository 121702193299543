.layout {
  .layout-content {
    .create-animal-page {
      min-height: 0;
    
      .page-body {
        background-color: $white;
        padding-bottom: 12px !important;
    
        form {
          display: flex;
          flex-direction: column;
          flex: 1;
    
          .submit-btn-container {
            display: flex;
            align-items: flex-end;
            flex: 1;
          }
        }
      }
    }
  }
}

.page.herd-page {
  .table-wrap table {
    thead {
      background-color: $white;
      box-shadow: $box-shadow;
    }
  
    tbody {
      .animal-name-cell {
        .avatar {
          margin-right: 12px;
        }
      }
      
      .animal-status-cell {
        &::before {
          content: '';
          display: inline-block;
          width: 8px;
          height: 8px;
          margin-right: 8px;
          margin-bottom: 1px;
          border-radius: 50%;
          background-color: $neutral-500;
        }
      
        &.active {
          &::before {
            background-color: $positive-500;
          }
        }
      }
    }
  }

  .animals-table-pagination {
    padding-top: 16px;
  }
}

.medication-modal {
  .modal-content {
    overflow: auto;
  }
}