.international-herd-form {
  height: 100%;

  .page.international-herd {
    height: 100%;

    .header {
      .search-input {
        min-width: 40%;
        margin-bottom: 0;

        .form-control {
          padding-left: 40px;
          background-color: $neutral-100;
          background-image: url('/icons/search.svg');
          background-position: 10px;
          background-repeat: no-repeat;
        }
      }
    }

    .page-body.international-herd {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .page-content {
        @include flexible-value(
          (
            prop: width,
            value: 50vw,
            value-sm: 100%,
          )
        );
      }
    }

    .page-body {
      .country-cell {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;

        .country-emoji {
          display: inline-block;
          margin: 0 !important;
          font-size: 24px;
          vertical-align: middle;
          line-height: 33px;
        }
      }

      .table-content-wrap {
        .family-tree-link-container {
          display: flex;
          justify-content: center;
        }

        .country-header {
          justify-content: center;
        }

        .avatar {
          margin-right: 12px;
        }

        .family-tree-icon {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 48px;
          height: 48px;
          background-color: $neutral-150;
          border-radius: 50%;

          svg {
            width: 32px;
            height: 32px;
          }
        }
      }

      .pagination-controls {
        margin-top: 11px;
      }
    }
  }
}
