.ios-toolbar-bg {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100000000000;

  width: 100%;
  height: env(safe-area-inset-top, 20px);

  background-color: black;

  @media (prefers-color-scheme: light) {
    background-color: white;
  }
}
