// to override styles correctly, it is necessary to wrap .react-custom-select in the body
body {
  @import 'input';
  @import 'select';
  @import 'prepend';

  .editor-form-group {
    z-index: 3;
  }

  .form-control {
    padding: calc(0.375rem + 1px) calc(0.75rem + 1px);
    position: relative;
    height: auto;
    border-radius: $round-md;
    color: $neutral-900;
    z-index: 2;
    border-color: hsl(0, 0%, 80%);;

    @include flexible-value((
      prop: min-height,
      value: 48px,
      value-xs: 44px,
    ));

    @include default-font-size;

    &:not(.is-invalid):hover {
      border-color: $primary;
    }

    &:focus {
      padding: 0.375rem 0.75rem;
      border: 2px solid $primary;
      box-shadow: none;
    }

    &::placeholder {
      color: $silver-gray;
    }

    &.is-invalid:focus {
      box-shadow: none;
      border: 2px solid $error-500;
    }
  }

  .react-custom-select__control {
    border-radius: $round-md;
    cursor: pointer;
    color: $neutral-900;

    &:hover {
      border-color: $primary;
    }

    &:focus {
      border-color: $primary;
    }

    .react-custom-select__single-value {
      color: $neutral-900;
    }

    .react-custom-select__value-container {
      .react-custom-select__multi-value {
        background-color: transparent !important;
      }
    }

    &.react-custom-select__control--is-focused {
      border: 1px solid $primary;
      box-shadow: $primary-focus-shadow;
    }
  }

  .react-custom-select__menu-portal {
    z-index: 9999;

    .react-custom-select__menu {
      z-index: 9999;
    }
  }

  .react-custom-select__placeholder {
    color: $silver-gray;
  }

  .react-custom-select__option {
    cursor: pointer;

    &:active {
      background-color: $primary-300;
    }

    &.react-custom-select__option--is-selected {
      background-color: $primary-300;
      color: $neutral-900;
    }

    &.react-custom-select__option--is-focused {
      background-color: $primary-200;
    }
  }

  .react-date-picker-input {
    z-index: 100;
  }

  .react-datepicker-popper {

    .react-datepicker {
      padding: 22px 26px;
      border: 0;
      border-radius: $round-md;
      box-shadow: $box-shadow;

      .react-datepicker__month-container {
        .react-datepicker__day-names {
          margin-top: 10px;
        }

        .react-datepicker__month {
          margin: 0;
        }

        .react-datepicker__day, .react-datepicker__day-name {
          margin: 0;
          display: inline-flex;
          width: 30px;
          height: 30px;
          border-radius: 50%;
          justify-content: center;
          align-items: center;

          @include inter-m-font;
          font-size: 14px;
        }

        .react-datepicker__day-name {
          font-weight: 600;
        }

        .react-datepicker__header {
          padding: 0;
          border-bottom: 0;
          background-color: $white;

          .react-datepicker__current-month {
            background-color: $white;
            color: $neutral-900;

            @include inter-m-font;
            font-size: 14px;
            font-weight: 600;
          }

          .react-datepicker__day-names {
            margin-bottom: 0;
            background-color: $white;
          }
        }

        .react-datepicker__month {
          .react-datepicker__week {
            .react-datepicker__day {
              transition: background-color .1s ease;
              outline: none;

              &--outside-month {
                color: $neutral-300;

                &:hover {
                  color: $neutral-900;
                }
              }

              &--keyboard-selected {
                background-color: $neutral-150;
                color: $neutral-900;
              }

              &:hover {
                background-color: $neutral-200;
              }

              &.react-datepicker__day--selected {
                background-color: $primary;
                color: $neutral-900;
              }
            }

            .react-datepicker__day--today {
              background-color: $neutral-150;
              color: $neutral-900;
            }
          }
        }
      }

      .react-datepicker__today-button {
        width: 100%;
        border-top: 0;
        background-color: $white;

        .btn {
          margin-top: 20px;
          width: 100%;
        }
      }

      .react-datepicker__triangle {
        display: none;
      }

      .react-datepicker__navigation {


        &--previous {
          left: 35px;
        }

        &--next {
          right: 35px;
        }

        .react-datepicker__navigation-icon {
          &::before {
            border-width: 2px 2px 0 0;
            border-color: $neutral-900;;
          }


        }
      }

      .react-datepicker-date {
        justify-content: center;
        cursor: default;

        @include inter-m-font;
        font-size: 14px;
        font-weight: 600;
      }

      .react-datepicker-header {
        .react-datepicker-select {
          display: flex;
          width: 100%;

          &:not(:first-child) {
            margin-top: 10px;
          }

          .react-datepicker-date {
            margin: 0;
            display: flex;
            align-items: center;
            line-height: 19px;
          }


          .react-datepicker-navigation {
            padding: 0;
            position: relative;
            height: 40px;
            width: 100%;
            display: flex;
            background: none;
            border: 0;
            justify-content: center;
            align-items: center;
            text-align: center;
            cursor: pointer;
            text-indent: -999em;
            overflow: hidden;

            &:hover {
              .react-datepicker-navigation-icon::after {
                border-color: $primary
              }
            }

            .react-datepicker-navigation-icon {
              position: relative;
              top: 8px;
              font-size: 20px;
              width: 100%;

              &::after {
                content: '';
                position: absolute;
                display: block;
                width: 7px;
                height: 7px;
                border-width: 2px 2px 0 0;
                border-color: $neutral-900;
                border-style: solid;
                top: 3px;
                transition: border-color .15s ease;
              }

              &-prev::after {
                transform: rotate(225deg);
                left: 20px;
              }

              &-next::after {
                transform: rotate(45deg);
                right: 20px;
              }
            }
          }
        }
      }
    }
  }

  .is-invalid {
    input.react-date-picker-input {
      border: 1px solid $error-500;

      &:focus {
        border: 1px solid $primary;
        box-shadow: 0 0 0 0.2rem rgba($error-500, 0.4);
      }
    }
  }

  form {
    .row {
      margin-right: -12px;
      margin-left: -12px;
    }
  }

  @import 'editor';
}

.react-custom-select-container {
  cursor: pointer;

  &.is-invalid {
    &.active {
      display: block;
    }
  }

  .react-custom-select__control {
    position: relative;

    @include flexible-value((
      prop: min-height,
      value: 48px,
      value-xs: 44px,
    ));
  }

  &.is-invalid {
    .react-custom-select__control {
      border-color: #EE3A3A;
      padding-right: calc(1.5em + 0.75rem);
      background-image: url('/icons/is-invalid.svg');
      background-repeat: no-repeat;
      background-position: right calc(0.375em + 0.1875rem) center;
      background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
    }
  }

  .react-custom-select__multi-value__label {
    background-color: $primary-400;
    border-top-left-radius: $round-md;
    border-bottom-left-radius: $round-md;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .react-custom-select__multi-value__remove {
    background-color: $primary-400;
    border-top-right-radius: $round-md;
    border-bottom-right-radius: $round-md;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.form-group {
  position: relative;
  display: flex;
  flex-direction: column;
  z-index: 2;

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active
  {
    box-shadow: 0 0 0 30px white inset;
  }

  .was-validated
  .form-control:invalid,
  .form-control.password.is-invalid {
    background-position: right calc(2.375em + 0.1875rem) center;

    &:focus {
      background-position: right calc(2.375em + 0.0975rem) center;
    }
  }

  .active {
    display: block;
  }

  .invalid-feedback {
    display: block;
  }

  .was-validated
  .form-control:invalid,
  .form-control.is-invalid,
  .form-control.is-invalid {
    &:focus {
      background-position: right calc(0.22em + 0.2575rem) center;
    }
  }

  .btn-secondary:not(:disabled):not(.disabled):active,
  .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    background-color: transparent;
    border-color: transparent;
  }

  .btn-secondary:not(:disabled):not(.disabled):active:focus,
  .btn-secondary:not(:disabled):not(.disabled).active:focus,
  .show > .btn-secondary.dropdown-toggle:focus {
    box-shadow: none;
  }

  .form-control.password {
    padding-right: 40px;
  }

  label:not(.rdw-link-modal-label):not(.rdw-link-modal-target-option) {
    position: absolute;
    padding: 0 4px;
    box-sizing: border-box;
    top: -10px;
    left: 12px;
    z-index: 150;

    @include label-font-size;

    &::after {
      content: '';
      position: absolute;
      top: 7px;
      left: 0;
      bottom: 6px;
      right: 0;
      background: $white;
      z-index: -1;
    }
  }

  .rdp-input-group {
    .rdp-calendar {
      .u-today-button {
        background-color: $primary;
        color: $neutral-900;

        @include poppins-m-font;

        &:hover {
          background-color: $primary-600;
          box-shadow: $primary-btn-focus-shadow;
        }
      }
    }
  }

  @include flexible-value((
    prop: margin-bottom,
    value: 31px,
    value-lg: 29px,
    value-xs: 27px,
  ));

  .date-modal-control {
    &.form-control {
      cursor: pointer;
      color: $neutral-900;
      transition: color .2s ease;

      &:not(:disabled):hover {
        color: $primary;
      }

      &:disabled {
        padding: 0.375rem 0.75rem;
        background-color: $white;
        border: 2px solid $primary;
      }
    }
  }
}

.password {
  .btn {
    position: absolute;
    padding: 6px 15px;
    width: 30px;
    height: 30px;
    top: 0px;
    right: 9px;
    background-color: transparent;
    background: url('/icons/Eye-off.svg') center no-repeat;
    z-index: 4;
    border: 0;

    &:focus {
      border: 0;
    }

    &:active {
      background-color: transparent;
      border: 0;
    }
  }

  .show-password {
    background: url('/icons/Eye.svg') center no-repeat;
  }

  .is-invalid {
    &.textarea-note {
      border: none;
      resize: none;
      min-height: 100px;
      max-height: 200px;

      &:focus {
        border: 1px solid #ced4da;;
        resize: vertical;
      }
    }
  }

  .modal-content {
    .form-group {
      z-index: 1;
    }

    .date-picker{
      z-index: 5;
    }
  }
}

