.collapse-with-header {
  width: 100%;
  display: flex;
  flex-direction: column;

  &:not(:last-child) {
    margin-bottom: 20px;
    margin-top: 20px;
  }

  .collapse-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: $primary;
    border-radius: $round-lg;
    border: none;
    width: 100%;
    height: 54px;
    outline: none;
    transition: $transition-base;
    padding: 10px 10px 10px 20px;
    text-decoration: none;

    &:hover,
    &:focus {
      color: inherit;

      &.active {
        box-shadow: $shadow-sm;
      }
    }

    .collapse-icon {
      margin-right: 8px;
       width: 20px;
       height: 20px;
    }

    .collapse-link {
      display: flex;
      align-items: center;
      padding: 20px;
      width: 100%;
      height: 100%;

      &:hover {
        text-decoration: none;
        color: $neutral-900;
      }

      &:focus {
        text-decoration: none;
        color: $neutral-900;
      }
    }

    .collapse-text {
      flex: 1;
      text-align: left;
    }

    .collapse-chevron-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
      width: 40px;
      height: 40px;
      border-radius: 6px;
      background-color: transparent;
      border: 0;
      transition: $transition-base;

      .collapse-chevron {
        transition: $transition-base;
      }
    }
  }

  &.open {
    .collapse-header {
      .collapse-chevron {
        transform: rotate(180deg);
      }
    }
  }
}
