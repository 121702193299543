.page.upcoming-medication {
  &>.header {
    display: flex;

    @include media-breakpoint-down(sm) {
      padding-bottom: 18px;
      flex-direction: column !important;
    }

    .heading {
      width: 100%;
      text-align: left;

      @include media-breakpoint-down(sm) {
        text-align: center;
      }
    }

    .slider-container {
      display: flex;
      justify-content: center;
      align-items: center;
      column-gap: 10px;

      .page-body-title {
        margin: 0;
        width: max-content;
        cursor: pointer;
      }
    }
  }
}
