.react-flow{
  @import 'edge-labels';

  .react-flow__handle {
    opacity: 0;
  }

  .tree-card {
    width: 200px;
    height: 310px;
    padding: 12px 16px;
    box-shadow: 0 0 14px -4px rgba(24, 39, 75, 0.08), 0px 0px 8px -4px rgba(24, 39, 75, 0.04);
    background-color: $neutral-100;
    border-radius: 8px;
    cursor: pointer;

    .details-container {
      display: flex;
      flex-direction: column;
      align-content: space-between;

      .separator-line {
        position:relative;
        width: 200px;
        margin-left: -18px !important;
        border: 1px solid #F6F6F6;
      }

      .image-container {
        display: flex;
        justify-content: center;
        align-self: center;
        box-shadow: 0 0 24px -4px rgba(44, 37, 67, 0.08), 0px 0px 12px -6px rgba(44, 37, 67, 0.08);
        border-radius: 50%;
      }

      .details {
        word-break: break-all;
        text-align: right;

        &.animal-name {
          overflow: hidden;
          text-overflow: ellipsis;

          word-break: break-word;
          text-align: center;
          margin-top: 8px;
          height: calc(var(--line-height) * 2);
          line-height: var(--line-height);
          --line-height: 24px;

          .item-value {
            display: -webkit-box;
            text-overflow: ellipsis;
            overflow: hidden;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }

          @include poppins-m-font();
        }

        &.animal-age {
          margin-top: 4px;
          color: $neutral-500;
          text-align: center;
          display: -webkit-box;
          text-overflow: ellipsis;
          word-break: keep-all;
          overflow: hidden;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;

          @include inter-xs-font();
        }

        &.animal-country {
          text-align: center;
        }

        &.animal-sex,  &.animal-herd, &.animal-color {
          display: flex;
          align-items: baseline;
          line-height: 20px !important;
          word-break: keep-all;

          .item-value {
            display: -webkit-box;
            text-overflow: ellipsis;
            word-break: break-word;
            overflow: hidden;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }

          @include inter-xs-font();

          .space-dots {
            flex: 1 1;
            margin: 0 2px;
            border-bottom: 1px dashed #E8E8E8;
          }
        }

        &.animal-color {
          .item-value {
            word-break: keep-all;
          }
        }
      }
    }
  }
}