.auth-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 72px;
  background-color: $gray-50;
  border-bottom: 1px solid $gray-200;

  @include flexible-value(
    (
      prop: padding,
      value: (
        0 256px,
      ),
      value-md: (
        0 128px,
      ),
      value-sm: (
        0 64px,
      ),
      value-xs: (
        0 12px,
      ),
    )
  );

  @include media-breakpoint-down(sm) {
    margin: env(safe-area-inset-top, 20px) env(safe-area-inset-right, 20px)
      0 env(safe-area-inset-left, 20px) !important;;
  }

  .select-language,
  .select-currency {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 170px;
    margin-bottom: 0;
    border: none;

    @include flexible-value(
      (
        prop: flex,
        value: 0 1 100%,
        value-xs: 1,
      )
    );

    @include flexible-value(
      (
        prop: justify-content,
        value: center,
        value-xs: flex-end,
      )
    );

    .form-group {
      width: 170px;
      margin: 0;
    }

    .select {
      .react-custom-select__control {
        min-height: 24px;
        background: transparent;
        border: none;
        border-radius: 0;

        &.react-custom-select__control--is-focused {
          box-shadow: none;
        }

        .react-custom-select__value-container {
          .react-custom-select__single-value {
            right: 0;
            color: $gray-900;
            font-family: $secondary-font, $font-family-base;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
          }
        }

        .react-custom-select__dropdown-indicator {
          padding: 0;
        }
      }
    }
  }
}

.select-currency {
  margin-left: auto;
  justify-content: flex-end !important;

  & > .form-group {
    width: 122px !important;

    @include media-breakpoint-down(xs) {
      width: 85px !important;
    }
  }
}

.react-custom-select__menu-portal {
  .language-select-menu {
    margin-top: 10px;
    box-shadow: 0 4px 22px -6px rgba(24, 39, 75, 0.08), 0px 8px 40px -4px rgba(24, 39, 75, 0.04);
    background: $neutral-100;
    border-radius: 8px;

    .react-custom-select__menu-list {
      padding: 6px 0;

      .react-custom-select__option {
        padding: 4px 12px 4px 16px;
        color: $gray-900;
        font-family: $secondary-font, $font-family-base;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;

        &.react-custom-select__option--is-focused {
          background-color: $gray-100;
        }

        &.react-custom-select__option--is-selected {
          display: flex;
          background-color: transparent;
        }

        .selected-option {
          display: flex;
          justify-content: space-between;

          svg {
            margin: 0 !important;
          }
        }
      }
    }
  }
}
