input[type=number]::-webkit-inner-spin-button {
  display: none;
}

input[type=checkbox] {
  display: inline-block;
  min-width: 18px;
  height: 18px;
  box-shadow: none !important;
  background-color: transparent;
  border: solid 2px $neutral-300;
  border-radius: $round-sm;
  outline: 0;
  cursor: pointer;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;

  &:checked {
    background-color: $primary-600;
    background-image: url('/icons/checked.svg');
    background-position: center;
    background-repeat: no-repeat;
    border-color: $primary-600;
  }
}

/* clears the ‘X’ from Internet Explorer */
input[type=search]::-ms-clear { display: none; width : 0; height: 0; }
input[type=search]::-ms-reveal { display: none; width : 0; height: 0; }
/* clears the ‘X’ from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration { display: none; }
