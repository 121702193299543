.verify-email {
  .auth-form {
    .verify-heading {
      font-family: $secondary-font, $font-family-base;
      font-weight: 600;
      font-size: 24px;
      line-height: 27px;
      text-align: center;
      color: $gray-900;

      @include flexible-value((
        prop: line-height,
        value: 27px,
        value-xs: 23px,
      ));

      margin-bottom: 16px;
    }

    .verify-email-text {
      font-family: $secondary-font, $font-family-base;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      text-align: center;
      color: $gray-500;
      margin-bottom: 0;
    }

    .custom-submit {
      @include flexible-value((
        prop: margin-top,
        value: 40px,
        value-xs: 24px,
      ));
    }
  }
}