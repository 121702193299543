.custom-badge {
  background-color: $error-500;
  margin-left: auto;
  border-radius: 8px;
  padding: 2px 8px 3px 8px;
}

.tip-badge {
  background-color: #F6F6F6;
  color: #666666;
  padding: 8px 12px;
  border-radius: 8px;
  margin-right: 8px;

  @include inter-m-font;
}