.mating {
  .create-btn:not(.empty) {
    @include flexible-values((
      prop: padding,
      directions: (left top right bottom),
      values: (42px, 12px, 16px, 12px),
      values-sm: (12px, 8px, 12px, 8px),
    ));

    @include media-breakpoint-down(sm) {
      position: relative;
      min-width: 0;
      width: 50px;
      height: 42px;

      &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 12px;
        height: 12px;
        background-image: url('/icons/plus.svg');
        transform: translate(-50%, -50%);
      }

      .create-btn-text {
        display: none;
      }
    }
  }
}

.create-mating-page {
  .page-body {
    background-color: $white;
    padding-bottom: 12px !important;

    form {
      display: flex;
      flex-direction: column;
      flex: 1;

      .submit-btn-container {
        display: flex;
        align-items: flex-end;
        flex: 1;
      }
    }
  }
}
