.empty-page {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;

  .empty-page-title {
    font-size: 30px;
    line-height: 32px;
  }

  .empty-page-text {
    margin-top: 15px;
    text-align: center;
  }

  .btn {
    position: absolute;
    top: 30px;
    right: 20px;
  }
}
