.member-modal {
  .modal-content {
    .custom-modal-header {
      padding-bottom: 0;
    }

    .modal-body {
      display: flex;
      flex-direction: column;

      .members-add-form {
        display: flex;
        flex-direction: column;
        flex: 1;

        .form-group:not(:last-child) {
          margin-bottom: 12px;
        }
      }

      .members-edit-form {
        @include flexible-value((
          prop: margin-bottom,
          value: 56px,
          value-sm: 48px,
        ));
      }

      .members-add-form, .members-edit-form {
        .email {
          margin: 0;
          display: flex;
          flex-direction: column;
          flex: 1;
        }

        h3 {
          margin-bottom: 28px;
        }

        .member-list {
          padding: 0;

          .member-list-item {
            display: flex;
            flex-direction: row;
            align-items: center;

            &:not(:last-child) {
              margin-bottom: 12px;
            }

            .member-email {
              overflow: hidden;
              padding: 7px 10px;
              border-radius: $round-md;
              width: 100%;
              white-space: nowrap;
              text-overflow: ellipsis;
              background-color: $wild-sand;
            }

            .member-remove-button {
              margin-left: 8px;
              border: 1px solid $mercury;
              min-height: 38px;
              min-width: 38px;
              padding: 0;
              background-color: transparent;
              color: $neutral-500;

              &:active {
                box-shadow: none;
              }

              &:hover {
                path {
                  stroke: $error-500;
                }
              }

              &:focus {
                path {
                  stroke: $error-500;
                }
              }

              svg {
                width: 18px;
                height: 18px;

                path {
                  transition: stroke .2s ease;
                }
              }
            }
          }
        }

        .buttons-form-group {
          margin-top: 15px;
          display: flex;
          justify-content: space-between;
          flex: 1;

          .btn {
            @include default-font-size;
          }
      
          @include flexible-value((
            prop: flex-direction,
            value: row,
            value-sm: column,
          ));

          @include flexible-value((
            prop: align-items,
            value: normal,
            value-sm: flex-start,
          ));

          .btn-link {
            padding-left: 0;

            &:hover {
              text-decoration: none;
            }
          }

          .btn-primary {
            margin: 0 0 0 auto;

            @include flexible-value((
              prop: width,
              value: 141px,
              value-sm: 100%,
            ));
          }
        }
      }
    }
  }
}
