.date-modal {
  .custom-modal-header {
    padding: 38px 76px 0;
    overflow: hidden;

    .close {
      margin: -40px -66px -16px auto;

      @include media-breakpoint-down(xs) {
        margin: -44px -76px -16px auto;
      }
    }
  }

  .modal-content {
    min-width: 0;
    width: auto;

    @include media-breakpoint-down(xs) {
      width: 100%;
    }

    .modal-body {
      padding: 0 50px 48px;
      display: flex;
      flex-direction: column;

      @include media-breakpoint-down(xs) {
        padding: 0 12px 12px;
      }
  

      .react-datepicker__tab-loop {
        flex: 1;
        display: flex;
        flex-direction: column;
      }
  
      .react-datepicker-wrapper {
        display: none;
      }

      .react-datepicker-popper {
        padding-top: 0;
        flex: 1;
        position: static !important;
        transform: none !important;

        div:not([class]) {
          display: flex;
          height: 100%;
        }

        .react-datepicker{
          padding: 0;
          display: flex;
          flex-direction: column;
          align-items: center;
          box-shadow: none;
          width: 100%;

          .react-datepicker__month-container {
            display: flex;
            flex-direction: column;
            flex: 1;

            .react-datepicker__day-names {
              margin-top: 45px;

              @include media-breakpoint-down(xs) {
                margin-top: 20px;
              }
            }
    
            .react-datepicker__month {
              min-height: 264px;
            }
    
            .react-datepicker__day, .react-datepicker__day-name {
              width: 44px;
              height: 44px;
              font-size: 16px;
              
            }
            
            .react-datepicker__header {
              display: flex;
              flex-direction: column;
              flex: 6;

              .react-datepicker-header {
                flex: 1;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
              }
            }
    
            .react-datepicker__month {
              flex: 9;
            }
          }

          .react-datepicker-header {
            .react-datepicker-select {
              .react-datepicker-date {
                font-size: 16px;
              }

              &:not(:first-child) {
                margin-top: 0;
              }

              .react-datepicker-navigation {


                &:hover {
                  .react-datepicker-navigation-icon::after {
                    border-color: $primary              }
                }
        
                .react-datepicker-navigation-icon {
     
                  &::after {
                    top: 3px;
                  }
                }
              }
            } 
          }
        }
      }
    }
  }
}