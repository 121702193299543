.page.welcome-page {
  .header {
    display: flex;

    @include media-breakpoint-down(sm) {
      margin-bottom: 18px;
      flex-direction: column !important;
    }

    .heading {
      width: 100%;
      text-align: left;

      @include media-breakpoint-down(sm) {
        text-align: center;
      }
    }

    .slider-container {
      display: flex;
      justify-content: center;
      align-items: center;
      column-gap: 10px;

      .page-body-title {
        margin: 0;
        width: max-content;
        cursor: pointer;
      }
    }
  }

  .page-body {
    background-color: $neutral-100;
    justify-content: space-between;
    box-shadow: $box-shadow;

    @include flexible-value((
      prop: margin-right,
      value: 28px,
      value-sm: 0,
    ));

    @include flexible-value((
      prop: margin-left,
      value: 28px,
      value-md: 20px,
      value-sm: 0,
    ));

    @include flexible-value((
      prop: border-radius,
      value: 8px !important,
      value-sm: 0,
    ));

    .page-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      height: 100%;

      .main-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        @include flexible-value((
          prop: margin-top,
          value: 118px,
          value-sm: 40px,
        ));

        @include flexible-value((
          prop: max-width,
          value: 480px,
          value-sm: 300px,
        ));

        .profile-link {
          color: $primary;
        }

        .title-content {
          @include poppins-xl-font;
          line-height: 36.68px;

          @include flexible-value((
            prop: margin-to,
            value: 56px,
            value-sm: 40px,
          ));

          @include flexible-values((
            prop: margin,
            directions: (top bottom),
            values: (56px, 24px),
            values-sm: (40px, 20px),
          ));
        }
      }

      .bottom-content {
        width: 250px;

        .form-checkbox {
          display: flex;
          align-items: center;
          align-self: flex-start;
          padding-left: 0;
        }

        @include flexible-value((
          prop: margin-bottom,
          value: 20px,
          value-sm: 7px,
        ));
      }
    }
  }
}

.form-checkbox-label {
  margin-bottom: 0;
  color: $neutral-800;

  &.for-sale {
    color: $neutral-900;
  }

  @include poppins-m-font;

  &:hover {
    cursor: pointer;
  }
}

.form-check-input {
  margin: 0;
}
