.auth-form {
  width: 100%;
  max-width: 482px;
  // max-height: 528px;
  display: flex;
  flex-direction: column;
  border-radius: $round-xl;
  background: $white;

  @include flexible-values((
    prop: padding,
    directions: (top left bottom right),
    values: (32px, 40px, 40px, 40px),
    values-xs: (24px, 16px, 24px, 16px),
  ));

  @include flexible-value((
    prop: box-shadow,
    value: (0px 4px 22px -6px rgba(24, 39, 75, 0.08), 0px 8px 40px -4px rgba(24, 39, 75, 0.04)),
    value-xs: (0px 4px 22px -6px rgba(24, 39, 75, 0.08), 0px 8px 40px -4px rgba(24, 39, 75, 0.04)),
  ));

  .custom-submit {
    @include link-button();
  }

  .auth-form-heading {
    flex: 4;
    font-family: $secondary-font, $font-family-base;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-weight: 600;

    @include flexible-value((
      prop: font-size,
      value: 24px,
      value-xs: 20px,
    ));

    @include flexible-value((
      prop: line-height,
      value: 27px,
      value-xs: 23px,
    ));

    @include flexible-value((
      prop: margin-bottom,
      value: 32px,
      value-xs: 24px,
    ));

  }

  & > .row {
    & > .col-12 {
      &:first-child {
        padding-right: 6px;
      }

      &:last-child {
        padding-left: 6px;
      }
    }
  }

  .form-group {
    margin-bottom: 10px;

    .custom-label {
      color: $gray-900;
      position: initial;
      padding: 0;
      z-index: auto;
      margin-bottom: 4px;

      font-family: $secondary-font, $font-family-base;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
    }

    & > .input-group {
      & > input {
        @include inter-m-font();
        padding: 12px 16px;
        color: $neutral-900;
        border: 1px solid $gray-200;
        border-radius: 8px;


        &:hover {
          border: 1px solid $gray-300;
        }

        &:focus {
          border: 1px solid $neutral-900;
        }

        &::placeholder {
          @include inter-m-font();

          color: $gray-400;
          opacity: 0.8;
        }
      }
    }
  }

  .password {
    margin-bottom: 0;
    .btn-secondary {
      top: 22px;
      right: 14px;
    }

    input {
      padding: 12px 44px 12px 16px !important;
    }
  }

  .divider {
    display: flex;
    height: 17px;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 24px 0 20px;

    .divider-text {
      margin: 0 12px;
      text-align: center;
      font-family: $secondary-font, $font-family-base;
      font-weight: 400;
      font-size: 12px;
      line-height: 17px;
      letter-spacing: 0.04em;
      text-transform: uppercase;
      color: $gray-400;
    }

    &::after,
    &::before {
      content: '';
      display: inline-block;
      flex: 1;
      height: 1px;
      border-bottom: 1px solid $gray-200;
    }
  }

  .btn-primary {
    @include flexible-value((
      prop: height,
      value: 64px,
      value-xs: 52px,
    ));
  }

  .auth-providers {
    display: flex;
    max-height: 209px;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;

    .auth-provider + .auth-provider {
      margin-top: 12px;
    }

    .auth-provider {
      height: 48px;
      display: flex;
      justify-content: flex-start;
      padding: 15px 44px 15px 20px;

      .auth-provider-logo {
        height: 24px;
        width: 24px;
      }

      .auth-provider-text {
        @include poppins-m-font;

        color: $white;
        margin: 0 auto;
        line-height: 22px !important;
      }

      &.google {
        background: #3B82F6;
        border-radius: 8px;

        &:hover {
          background: #2563EB;
          transition: background-color 0.2s ease-out;
        }

        &:active {
          background-color: #1D4ED8;
          outline: none;
          transition: background-color 0.2s ease-out;
        }

        &:focus {
          outline: none;
          box-shadow: none;
        }
      }

      &.apple {
        background: $gray-900;
        border-radius: 8px;

        &:hover {
          background-color: $gray-800;
          transition: background-color 0.2s ease-out;
        }

        &:active {
          background-color: $gray-800;
          outline: none;
          transition: background-color 0.2s ease-out;
        }

        &:focus {
          outline: none;
          box-shadow: none;
        }
      }
    }
  }
}




