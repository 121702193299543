.recovery {
  @include flexible-value((
    prop: padding-top,
    value: 56px,
    value-xs: 88px,
  ));

  .auth-form {

    .auth-form-heading {
      margin-bottom: 16px;
    }

    .btn-back {
      margin: 15px 0 -28px;
      color: $neutral-300;

      &:hover {
        color: $neutral-500;
      }
    }

    .form-group:last-child {
      padding: 0;
      width: 100%;
    }

    .form-group {
      margin-bottom: 0;

      @include flexible-value((
        prop: flex,
        value: 1,
        value-xs: 0,
      ));
    }

    .recovery-text {
      @include default-font-size;
      font-family: $secondary-font, $font-family-base;
      text-align: center;
      color: $gray-500;

      @include flexible-value((
        prop: margin-bottom,
        value: 32px,
        value-xs: 24px,
      ));
    }
  }
}
