.herd-page,
.herds-page {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  width: fit-content;

  .herds {
    flex: 1;

    @import 'herds-list';
  }

  .upgrade-btn {
    @include flexible-values((
      prop: padding,
      directions: (left top right bottom),
      values: (45.5px, 12px, 45.5px, 12px),
      values-sm: (12px, 8px, 12px, 8px),
    ));

    .upgrade-text {
      @include poppins-m-font()
    }
  }

  .create-btn:not(.empty) {
    @include flexible-values((
      prop: padding,
      directions: (left top right bottom),
      values: (42px, 12px, 16px, 12px),
      values-sm: (12px, 8px, 12px, 8px),
    ));

    @include media-breakpoint-down(sm) {
      position: relative;
      min-width: 0;
      width: 50px;
      height: 42px;

      &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 12px;
        height: 12px;
        background-image: url('/icons/plus.svg');
        transform: translate(-50%, -50%);
      }

      .create-btn-text {
        display: none;
      }
    }

    &.empty {
      min-width: min-content;
    }
  }

  .page-body-header .count-hint {
    min-width: 26px;
    height: 17px;
    padding: 0 6px;
    border-radius: 9px;
    background-color: $primary;
    text-align: center;
    font-size: 12px;
    font-weight: bold;
  }
}

.herd-page {
  .header {
    @include flexible-value((
      prop: flex-direction,
      value: row,
      value-xs: column,
    ));

    .header-right-container {
      @include flexible-value((
        prop: justify-content,
        value: flex-end,
        value-xs: space-between,
      ));

     @include flexible-value((
      prop: width,
      value: auto,
      value-xs: 100%,
     ));
    }
  }
}

  .herds {
    flex: 1;

    @import 'herds-list';
  }

  .page-body-header .count-hint {
    min-width: 26px;
    height: 17px;
    padding: 0 6px;
    border-radius: 9px;
    background-color: $primary;
    text-align: center;
    font-size: 12px;
    font-weight: bold;
  }

.layout {
  .layout-content {
    .herd-page {
      .header {
        @include flexible-value((
          prop: align-items,
          value: center,
          value-xs: flex-start,
        ));
      }
    }

    .herds-page { 
      .page-body {
        background: transparent;
        box-shadow: none;
        padding: 0;
      }
    }
  }
}