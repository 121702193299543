.sale-page {
  .bottom-header {
    padding-top: 0 !important;
    padding-bottom: 0 !important;

    .content {
      display: flex;
      align-items: center;
      width: 100%;
      background-color: $white;
      border-radius: $round-lg;
      gap: 24px 0;

      @include flexible-value((
        prop: gap,
        value: 24px 0,
        value-xs: 10px,
      ));

      @include flexible-value((
        prop: flex-flow,
        value: wrap,
        value-sm: wrap-reverse,
        value-xs: wrap-reverse,
      ));

      @include flexible-values((
        prop: padding,
        directions: (top right bottom left),
        values: (12px 26px 12px 26px),
        values-sm: (27px 26px 27px 26px),
      ));

      .separator {
        display: inline-block;
        border: 1px solid $neutral-300;

        @include flexible-value((
          prop: margin,
          directions: (top right bottom left),
          value: (0 20px 0 0),
          value-sm: (0 20px 0 0),
          value-xs: (0),
        ));

        @include flexible-value((
          prop: height,
          value: 26px,
          value-sm: 0,
          value-xs: 0,
        ));

        @include flexible-value((
          prop: width,
          value: 0,
          value-sm: 100%,
          value-xs: 100%,
        ));
      }

      label {
        display: flex;
        align-items: center;
        margin: 0 20px 0 0;

        input {
          margin-right: 12px;
        }

        @include label-font-size;
      }
    }
  }

  .table-content-wrap {
    .avatar {
      margin-right: 12px;
    }

    .country-header {
       align-items: center;
       justify-content: center;
    }

    .country-cell {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;

      .country-emoji {
        display: inline-block;
        margin: 0 !important;
        font-size: 24px;
        vertical-align: middle;
        line-height: 33px;
      }
    }
  }

  .page-body {

    @include flexible-value((
      prop: padding-top,
      value: 16px !important,
      value-xs: 12px !important,
    ));

    .pagination-controls {
      margin-top: 11px;
    }
  }
}

.sale-page-profile {
  @include flexible-value((
    prop: padding-right,
    value: 34px,
    value-sm: 0,
  ));

  .page-body {
    display: inline-block;
    background-color: $white;

    @include flexible-value((
      prop: padding,
      value: 28px !important,
      value-sm: 16px !important,
    ));

    @include flexible-value((
      prop: flex,
      value: 0 !important,
      value-sm: 1 1,
    ));

    .content {
      .details {
        .type-column {
          @include flexible-value((
            prop: min-width,
            value: 70px,
            value-md: auto,
          ));
        }

        .sex-column {
          @include flexible-value((
            prop: min-width,
            value: 70px,
            value-md: auto,
          ));
        }
        margin-top: 16px;

        .row {
          @include flexible-value((
            prop: grid-row-gap,
            value: 12px,
            value-sm: 8px,
          ));
        }

        .row:last-child{
          margin-top: 12px;
        }

        p {
          height: 100% !important;
          overflow-wrap: break-word !important;
        }
      }

      .breeder-info {
        margin-top: 32px;

        p, h6 {
          overflow-wrap: break-word !important;
        }

        .row {
          @include flexible-value((
            prop: grid-row-gap,
            value: 12px,
            value-sm: 8px,
          ));
        }

        .breeder-information-label {
          @include flexible-value((
            prop: margin-bottom,
            value: 12px !important,
            value-sm: 16px !important,
          ));
        }

      }

      .animal-name, .breeder-info-heading {

        @include flexible-value((
          prop: margin-bottom,
          value: 16px !important,
          value-sm: 0 !important,
        ));
      }

      .name {
        overflow-wrap: break-word;
      }

      p, span{
        padding-right: 5px;
        margin: 0;
        color: $neutral-800;

        .name {
          overflow-wrap: break-word;
        }

        @include media-breakpoint-only(xs) {
          font-size: 12px;
          font-weight: 400;
          line-height: 20px;
        }
      }

      h6 {

        @include media-breakpoint-only(xs) {
          font-size: 12px;
          font-weight: 400;
          line-height: 20px;
        }
      }

      .animal-info {
        display: flex;
        width: auto;

        div {
          .animal-name {
            overflow: hidden;

            h3 {
              overflow: hidden;
              text-overflow: ellipsis;

              @include poppins-l-font;

              @include media-breakpoint-only(sm) {
                @include poppins-m-font;
              }
            }
          }
        }

        @include flexible-value((
          prop: flex-direction,
          value: row,
          value-sm: column,
        ));

        .avatar {
          max-width: unset !important;

          @include flexible-value((
            prop: width,
            value: 112px !important,
            value-sm: 56px !important,
          ));

          @include flexible-value((
            prop: height,
            value: 112px !important,
            value-sm: 56px !important,
          ));

          img {
            max-width: unset !important;
            border-radius: $round-md;

            @include flexible-value((
              prop: width,
              value: 112px !important,
              value-sm: 56px !important,
            ));

            @include flexible-value((
              prop: height,
              value: 112px !important,
              value-sm: 56px !important,
            ));
          }
        }

        .description {
          width: 100%;
          overflow: hidden;

          p {
            color: $neutral-900 !important;
            height: 100%;
            @include inter-m-font;

            @include flexible-value((
              prop: margin-top,
              value: 12px,
              value-sm: 8px,
            ));
          }

          .rdw-editor-wrapper {
            span {
              color: $neutral-900 !important;
            }

            .public-DraftStyleDefault-block {
              margin: 12px 0 0 !important;
            }
          }

          .label-muted {
            @include label-muted;
          }


          @include flexible-value((
            prop: padding-left,
            value: 32px,
            value-sm: 0,
          ));

        }
      }
    }
  }
}