.bulk-image-cropper {
  margin-top: 51px;

  .header {
    display: flex;
    justify-content: space-between;
    padding: 0;

    .right-side {
      .btn-link {
        &:focus {
          outline: none;
          box-shadow: none;
        }
      }
    }
  }

  .sc-AxirZ {
    border: none;
    padding-left: 0;
    padding-right: 0;

    &>.sc-AxhCb {
      div:not(.sc-fznKkj):not(.sc-AxjAm) {
        height: 200px;
        width: 136px;
        border: 1px solid #BDBDBD;
        background-color: #F7F7F7;
        background-image: url('/icons/plus.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: 12px;
        border-radius: 0 0 8px 8px;

        &:hover, &:focus {
          border: 1px solid #FFC04C;
          background-image: url('/icons/plus-active.svg');
        }

        &>svg {
          display: none;
        }

        &>.sc-Axmtr {
          display: none !important;
        }
      }

      &>.sc-AxjAm {
        height: 200px;
        width: 136px;
        border-radius: 0 0 8px 8px;
        overflow: hidden;

        &>.sc-AxheI {
          border-radius: 0 0 8px 8px;
        }
      }

      .sc-fznKkj {
        margin-right: 0.5rem;
      }
    }
  }

  .sc-fzoLsD {
    z-index: 9999999;
    .sc-AxmLO {
      max-width: 800px;
      .sc-fzpans {
        display: none;
      }

      .sc-fznyAO {
        .sc-AxhUy {
          font-family: $secondary-font, $font-family-base;
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
        }
      }
    }
  }
}