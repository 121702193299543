.control-field {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  column-gap: 24px;
  row-gap: 8px;

  &__label {
    width: 250px;
    margin: 0;

    &-text {
      display: block;

      margin-bottom: 2px;
      color: $warm-gray-900;

      font-size: 16px;
      font-family: Inter;
      font-weight: 500;
      line-height: 24px;
    }

    &-required {
      color: $red-600;
    }

    &-helper {
      display: block;

      margin: 0;
      color: $warm-gray-500;

      font-size: 14px;
      font-family: Inter;
      line-height: 20px;
    }
  }

  &__input {
    width: 100%;
    flex: 1 0 60%;
  }

  @include media-breakpoint-down(sm) {
    &__label {
      width: 100%;

      &-text {
        margin-bottom: 0;
      }
    }
  }
}
