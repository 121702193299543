.achievements-modal {
  .modal-content {
    box-shadow: $box-shadow;
    border: none;

    .achievements-modal-header {
      background-color: #FFF5DE;
      height: 136px !important;

      .close {
        span {
          color: $primary-600;
          text-shadow: none;
        }
      }
    }

    .achievements-modal-body {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0 40px 48px;

      .image-container {
        position: absolute;
        top: -30%;

        @include flexible-value((
          prop: top,
          value: -30%,
          value-xs: -15%,
        ));


        .achievement-image {
          border-radius: 50%;
          background-color: #FFF5DE;
          height: 176px;
          width: 176px;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            width: 100px;
            height: 100px;
          }
        }
      }

      .modal-title {
        margin-top: 112px;
        text-align: center;

        @include poppins-xl-font;
      }

      .modal-description {
        margin: 16px 0 0;
        color: $neutral-800;
        text-align: center;
        max-width: 343px;

        @include inter-m-font;
      }
    }
  }
}
