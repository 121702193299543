.control-table__header {
  padding: 12px;

  border-radius: 8px;
  border: 1px solid $gray-200;
  background: $gray-50;
}

.control-table__title {
  display: block;

  margin: 0;
  color: #78716c;
  font-size: 14px;
  font-family: Inter;
  line-height: 20px;
}
